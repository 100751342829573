.myGal {
    height: 480px;
}
.myGal .vkuiHorizontalScroll__arrow-right {
    top: 100px;
    height: 280px;
}
.likeButton {
    #background-color: #edeef0;
    background-color: var(--background_content);
}
.img_div {
    display: block;
    width: 100%;
    height: 480px;
    overflow: hidden;
}
.img_div_img {
    width: 100%;
    height: 480px;
    object-fit: cover;
}